import React, { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import { ComingSoon } from '../components/ComingSoon';
import { LinkCardNewsPost } from '../components/LinkCardNewsPost';
import { Main } from '../components/Main';
import { PaginatedGrid } from '../components/PaginatedGrid';
import { usePageContext } from '../context/PageContext';
import { NewsPostQuery } from './NewsPost';

export type NewsCollectionQuery = {
  contentfulPageNewsCollection: {
    title: string;
    description?: string;
  };
  allContentfulPageNewsPost: {
    nodes: NewsPostQuery['contentfulPageNewsPost'][];
  };
};

const NewsCollection = ({
  data,
}: PageProps<NewsCollectionQuery>): ReactElement => {
  const { locale } = usePageContext();

  const { contentfulPageNewsCollection, allContentfulPageNewsPost } = data;
  const { title } = contentfulPageNewsCollection;
  const posts = allContentfulPageNewsPost.nodes;

  return (
    <Main pageTitle={title} variant={'narrow'}>
      {!posts?.length ? (
        <ComingSoon />
      ) : (
        <PaginatedGrid
          itemsPerPage={10}
          items={posts.map((post, i) => (
            <LinkCardNewsPost
              key={`upcoming-${i}`}
              language={locale || 'fi'}
              {...post}
            />
          ))}
          gap={'1rem'}
        />
      )}
    </Main>
  );
};

export default NewsCollection;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    contentfulPageNewsCollection(id: { eq: $id }) {
      ...NewsCollection
    }
    allContentfulPageNewsPost(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ... on ContentfulPageNewsPost {
          ...NewsPostLink
        }
      }
    }
  }
`;
